import Swiper from 'swiper'

window.addEventListener('DOMContentLoaded', () => {
  let delay_change = false;
  // 注目の病院情報カルーセル
  const hospitalSwiper = document.querySelectorAll('.m__carousel--hospital-list .hospital-swiper')
  const hospitalPrev = document.querySelectorAll('.m__carousel--hospital-list .swiper-button-prev')
  const hospitalNext = document.querySelectorAll('.m__carousel--hospital-list .swiper-button-next')
  const carouselType = document.querySelectorAll('.m__top__search-area')
  if (hospitalSwiper.length > 0) {
    for (let i = 0; i < hospitalSwiper.length; i++) {
      hospitalSwiper[i].className += i
      hospitalPrev[i].className += i
      hospitalNext[i].className += i
      const sliderCount = hospitalSwiper[i].children[0].children.length
      let loopSetting = true
      let slidesPerView = 3
      loopSetting = sliderCount > 2;
      if (window.innerWidth > 767) {
        if (carouselType[0].classList.contains('m__top__search-area--student') === true || carouselType[0].classList.contains('m__top__search-area--resident') === true) {
          slidesPerView = 2
          loopSetting = sliderCount > 1;
        }
      }
      const swiper = new Swiper ('.hospital-swiper' + i, {
        'loop': loopSetting,
        'slidesPerView': slidesPerView,
        'spaceBetween': 10,
        'autoplay': {
          delay: 8000, // custom first time delay
        },
        'navigation': {
          'nextEl': '.m__carousel--hospital-list .js-swiper-next' + i,
          'prevEl': '.m__carousel--hospital-list .js-swiper-prev' + i
        },
        'watchOverflow': true,
        'breakpoints': {
          '768': {
            'slidesPerView': 2,
            'spaceBetween': 6
          }
        },
        'on': {
          'slideChangeTransitionStart': function() {
            if (swiper && !delay_change) {
              for (let i = 0; i < swiper.slides.length; i++) {
                let slide = swiper.slides[i]
                slide.setAttribute('data-swiper-autoplay', '5000')
              }
              delay_change = true
            }
          }
        }
      })
    }
  }
})
